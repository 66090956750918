/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './drop-up.scss';
import {
  faHandHoldingUsd,
  faCaretUp,
  faSave,
  faCaretDown,
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import CartCobrarBtn from './cart-buttons/CartCobrarBtn';
import CartCotizatbtn from './cart-buttons/CartCotizatbtn';
import CartGuardarBtn from './cart-buttons/CartGuardarBtn';
import VentaContainer from './containers/VentaContainer';
import {
  changeSalesOrderStatus,
  makeSell,
  saveSell,
} from '../../../redux/actions/sellActions';
import VentaContainerListOption from './containers/VentaContainerListOption';
import DropUpButton from './DropUpButton';
import useOnClickOutside from '../../../hooks/useClickOutside';
import { setCartActionSelected } from '../../../redux/actions/PosV2Actions';

const CartSellAction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [optionSelected, setOptionSelected] = useState('');
  const authUser = useSelector(state => state.authUser);
  const tabs = useSelector(state => state.tabs);
  const cart = useSelector(state => state.cart);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));
  const withDiscount = Number.parseFloat(cart.total);
  const generalBalance = withDiscount - Number.parseFloat(cart.pagado);
  const {
    user: {
      config: {
        pos_botoncaja, pos_sell_tab, pos_no_cobrar, store, pos_no_crear_so, pos_permite_cotizar,
      },
    },
  } = authUser;

  const canCobrar = pos_no_cobrar !== '1';
  const canCreate = pos_no_crear_so !== '1';
  // const canDeliver = pos_no_entregar !== '1';
  const canQuote = pos_permite_cotizar === '1'; // -> TODO

  const mostrarCotizar = Boolean(pos_botoncaja === 'Cotizar') && canQuote;
  const mostrarCobrar = Boolean(pos_botoncaja === 'Cobrar') && canCobrar;
  const mostrarGuardar = Boolean(pos_botoncaja === 'Guardar') && canCreate;
  const moreThanOne = [canCobrar, canCreate, canQuote].filter(Boolean);

  function selectingOption(opt) {
    setOptionSelected(opt);
    setOpen(false);
  }

  function defaultOptionSelected() {
    if (!optionSelected && mostrarCobrar) {
      setOptionSelected('CobrarVender');

      return;
    }

    if (!optionSelected && mostrarGuardar) {
      setOptionSelected('GuardarVender');

      return;
    }
    setOptionSelected('CotizarVender');
  }

  const RenderListOfOptions = () => (
    <div
      className="drop-up-options"
      style={{ height: open ? 'auto' : 0, overflow: 'hidden' }}
    >
      {tabs.posTypeTab === 'Vender' && (
        <>
          {canCobrar && <DropUpButton title="Cobrar" bordered={false} icon={faHandHoldingUsd} onClick={() => selectingOption('CobrarVender')} />}
          {canQuote && <DropUpButton color="#70BBFD" bordered={false} title="Cotizar" icon={faFileInvoice} onClick={() => selectingOption('CotizarVender')} />}
          {canCreate && <DropUpButton color="#787985" bordered={false} title="Guardar" icon={faSave} onClick={() => selectingOption('GuardarVender')} />}
        </>
      )}
      {tabs.posTypeTab === 'Historico' && <VentaContainerListOption selectOption={selectingOption} />}
      {tabs.posTypeTab === 'Cotizaciones' && (
        <DropUpButton title="Generar Venta" icon={faFileInvoice} onClick={() => selectingOption('CotizarCotizar')} />
      )}
    </div>
  );
  const RenderOption = () => (
    <>
      {optionSelected === 'CobrarVender' && canCobrar
        && (
        <CartCobrarBtn
          modalTitle="Cobrar"
          title="Cobrar"
          totalToPay={generalBalance}
          onSave={async (checkOut) => {
            // eslint-disable-next-line no-shadow
            const { currentOrderStatus, payments } = checkOut;
            dispatch(changeSalesOrderStatus(currentOrderStatus));
            await dispatch(makeSell(payments));
            if (pos_sell_tab) {
              history.push('/pos/ventas');
            }
          }}
        />
        )
      }
      {optionSelected === 'CotizarVender' && canQuote && <CartCotizatbtn />}
      {optionSelected === 'GuardarVender' && canCreate && <CartGuardarBtn />}
      {
        !optionSelected && !mostrarCobrar && !mostrarCotizar && !mostrarGuardar
        && (
        <CartCobrarBtn
          modalTitle=""
          title="Cobrar"
          totalToPay={generalBalance}
          onSave={async (checkOut) => {
            // eslint-disable-next-line no-shadow
            const { currentOrderStatus, payments } = checkOut;
            dispatch(changeSalesOrderStatus(currentOrderStatus));
            await dispatch(makeSell(payments));
            if (pos_sell_tab) {
              history.push('/pos/ventas');
            }
          }}
        />
        )
      }
    </>
  );

  function handleSelectedStore() {
    const stores = authUser.user.almacenes;
    const findStoreSelected = stores.find(s => s.crmid === store.crmid);
    if (findStoreSelected) {
      setSelectedStore(findStoreSelected);
    }
  }

  function handleCotizar() {
    dispatch(changeSalesOrderStatus('Created'));
    dispatch(saveSell());
    history.push('/pos/ventas');
  }

  useEffect(() => {
    if (tabs.posTypeTab === 'Vender') {
      defaultOptionSelected();
    }
  }, []);

  useEffect(() => {
    if (optionSelected) {
      dispatch(setCartActionSelected(optionSelected));
    }
  }, [optionSelected]);

  useEffect(() => {
    handleSelectedStore();
  }, [store]);

  return (
    <div ref={ref} className="drop-up-container" style={{ width: '100%' }}>
      {
        cart.sinStock && tabs.posTypeTab === 'Vender' && (
          <span style={{ color: 'red' }}>Hay productos sin inventarios en el carrito, revise bien antes de realizar cualquier acción</span>
        )
      }
      {open && <RenderListOfOptions />}
      <div>
        {tabs.posTypeTab === 'Vender' && !open && <RenderOption />}
        {tabs.posTypeTab === 'Historico' && !open && <VentaContainer optionSelected={optionSelected} setOptionSelected={setOptionSelected} />}
        {tabs.posTypeTab === 'Cotizaciones' && !open && (
        <DropUpButton title="Generar Venta" icon={faFileInvoice} onClick={cart.totalProducts > 0 ? () => handleCotizar() : () => {}} />
        )}
      </div>
      {
        tabs.posTypeTab !== 'Cotizaciones' && !(tabs.posTypeTab === 'Vender' && moreThanOne.length <= 1) && !open && (
          <div className="drop-up-toggle-1" onClick={() => setOpen(!open)}>
            <FontAwesomeIcon
              icon={!open ? faCaretUp : faCaretDown}
              style={{ fontSize: 15 }}
            />
          </div>
        )
      }
    </div>
  );
};

export default CartSellAction;
