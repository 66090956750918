/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FullDialog from '../FullDialog';
import {
  saveTimbrado,
  fetchTimbrado,
} from '../../redux/actions/TimbrarActions';
import { openModal, closeModal } from '../../redux/actions/modalActions';
import Confirm from '../Confirm';
import TimbreDialog from './components/TimbreDialog';
import { formatMoney } from '../../helpers/tools';

const Timbre = ({
  title, isOpen, onSave, timbrar, onClose, isLoading,
}) => {
  const cart = useSelector(state => state.cart);
  const [data, setData] = useState({});
  const [confirmIsOpen, changeConfirm] = useState(false);
  const { confirma, rfc_receptor } = timbrar;
  const {
    products, subTotal, total, totalIva, anotherTaxes,
  } = cart;

  const handleTax = () => {
    const otherTax = anotherTaxes ? parseFloat(anotherTaxes) : 0;
    return formatMoney(parseFloat(totalIva) + otherTax);
  };

  return (
    <FullDialog
      title={title}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onSave={() => {
        changeConfirm(true);
      }}
    >
      <div style={{ maxWidth: '1200px', padding: '0 30px' }}>
        <TimbreDialog timbrar={timbrar} data={data} setData={setData} />
        <div style={{ marginTop: 30 }}>
          <h3>Conceptos</h3>
        </div>
        <div style={{ marginTop: 10 }}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid gray', marginTop: '1px solid gray' }}>
                <th>Código</th>
                <th>Nombre</th>
                <th style={{ textAlign: 'center' }}>Cantidad</th>
                <th style={{ textAlign: 'center' }}>PU</th>
                <th style={{ textAlign: 'center' }}>IMP</th>
                <th style={{ textAlign: 'center' }}>Discount</th>
                <th style={{ textAlign: 'center' }}>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {
                products.map((product, index) => (
                  <tr key={index} style={{ padding: '10px', borderBottom: '1px solid gray' }}>
                    <td>{product.productcode}</td>
                    <td>{product.productname}</td>
                    <td style={{ textAlign: 'center' }}>{product.quantity}</td>
                    <td style={{ textAlign: 'center' }}>
                      $ 
                      {formatMoney(product.unit_price === 0.01 ? 0 : product.unit_price)}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {parseFloat(product.tax1) * 100 / 100}
                      {' '}
                      %
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {parseFloat(product.discount_percent) || 0}
                      {' '}
                      %
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      $ 
                      {formatMoney(product.unit_price === 0.01 ? 0 : (product.unit_price * product.quantity - (product.unit_price * product.quantity * (product.discount_percent / 100))))}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'end',
            gap: '30px',
            marginTop: 20,
          }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
            >
              <strong>Subtotal:</strong>
              <strong>Impuestos:</strong>
              <strong>Total:</strong>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
            >
              <span>
                $
                {' '}
                { formatMoney(subTotal) }
              </span>
              <span>
                $
                {' '}
                {handleTax()}
              </span>
              <span>
                $
                {' '}
                {formatMoney(total)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        isOpen={confirmIsOpen}
        title="Confirmar"
        description={(
          <div className="p-1">
            <p>
              <strong>Factura: </strong>
              <span>{confirma.Factura}</span>
            </p>
            <p>
              <strong>Modo: </strong>
              <span>{confirma.Modo}</span>
            </p>
            <p>
              <strong>RFC Emisor: </strong>
              <span>{confirma['RFC Emisor']}</span>
            </p>
            <p>
              <strong>RFC Receptor: </strong>
              <span>{rfc_receptor}</span>
            </p>
            {rfc_receptor === 'XAXX010101000' && (
              <div
                className="warning"
                style={{
                  marginTop: '1rem',
                  padding: '5px',
                  backgroundColor: '#eb715f',
                  color: 'white',
                  fontSize: 14,
                  borderRadius: 5,
                }}
              >
                <span>
                  Estará usando el RFC de público general
                </span>
              </div>
            )}
          </div>
        )}
        onClose={() => {
          changeConfirm(false);
        }}
        onConfirm={(response) => {
          if (response) {
            const { crmid } = timbrar;
            onSave({ ...data, crmid });
          }
        }}
      />
    </FullDialog>
  );
};

Timbre.propTypes = {
  title: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  timbrar: PropTypes.object.isRequired,
};

Timbre.defaultProps = {
  title: 'Timbrar Orden',
};

const mapStateToProps = (state) => {
  const { cart, loads, modals } = state;
  const { orderSelected } = cart;
  const existOrder = Boolean(
    Object.keys(orderSelected).length > 0 && orderSelected.order,
  );
  const orderNo = existOrder ? orderSelected.order.label : '';
  const title = `Timbrar orden: ${orderNo}`;
  const isOpen = Boolean(modals.timbrarModalIsOpen);
  const isLoading = Boolean(loads.timbrarIsLoading);
  return {
    title,
    isOpen,
    isLoading,
    timbrar: state.timbrar,
  };
};

const mapDispatchToProps = dispatch => ({
  /* Timbrar Actions */
  onClose: () => dispatch(closeModal('timbrar')),
  onSave: timbre => dispatch(saveTimbrado(timbre)),
  dispatchFetchTimbrado: crmid => dispatch(fetchTimbrado(crmid)),
  dispatchOpenTimbrarModal: () => dispatch(openModal('timbrar')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timbre);
