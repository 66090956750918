/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  List,
  Paper,
  Divider,
  ListItem,
  Typography,
  withStyles,
  CssBaseline,
  ListItemText,
  ListSubheader,
  Collapse,
  Chip,
  ExpansionPanel,
} from '@material-ui/core';
import {
  Face,
  StoreMallDirectory,
} from '@material-ui/icons';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  fullWidth: {
    width: '100%',
  },
});

const StoreForm = ({
  classes,
  selectBC,
  authUser,
  flagMethods,
  selectStore,
  titleMethods,
}) => {
  const { user } = authUser;
  const { almacenes, config } = user;
  const conf = Boolean(config.pos_config_alm);
  const sucursal = almacenes.filter(a => a.talm_tipo === 'Almacen/Sucursal');
  const repartidor = almacenes.filter(a => a.talm_tipo === 'Repartidor');
  const dropshipping = almacenes.filter(a => a.talm_tipo === 'Dropshipping - No aparta' || a.talm_tipo === 'Dropshipping - Si aparta');
  const [sucursalPanel, setSucursalPanel] = useState(sucursal.length);
  const [repartidorPanel, setRepartidorPanel] = useState(!sucursal.length && repartidor.length);
  const [dropshippingPanel, setDropshippingPanel] = useState(!sucursal.length && !repartidor.length && dropshipping.length);

  return (
    <section className={classes.main}>
      <CssBaseline />
      <div className="mt-4 w-100 h-auto d-flex flex-column align-items-center">
        <span className="font-size-12">Configuración predeterminada:</span>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Chip
            label={conf
              ? 'Configuraciones del almacén'
              : 'Configuraciones del Usuario'}
            variant="outlined"
            color={conf ? 'primary' : 'secondary'}
            icon={conf ? <StoreMallDirectory /> : <Face />}
          />
        </div>
      </div>
      <Paper className={classes.paper} style={{ maxHeight: '90vh', overflow: 'auto' }}>
        <Typography component="h1" variant="h5">
          Configuraciones previas
        </Typography>
        <Collapse in className="w-100">
          <Typography component="h4" variant="h6" style={{ marginTop: 20 }}>
            Selecciona un almacén
          </Typography>
          {
            sucursal.length > 0 && (
              <ExpansionPanel className="w-100" expanded={sucursalPanel} onClick={() => setSucursalPanel(!sucursalPanel)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Almacén Sucursal</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <List
                    className={classes.fullWidth}
                  >
                    {
                    sucursal.map(store => (
                      <ListItem
                        key={`StoreForm-${store.crmid}`}
                        button
                        component="button"
                        onClick={(event) => {
                          event.preventDefault();
                          const { currentTarget } = event;
                          selectStore(currentTarget, { ...store, pos_config_alm: conf });
                        }}
                        data-pos={store}
                        data-crmid={store.crmid}
                        data-name={store.almacen}
                        data-telefono={store.telefono}
                        data-email={store.email}
                        data-url_web={store.url_web}
                        data-image={(store.images && store.images.length > 0) ? store.images[0] : ''}
                        data-campos={store.talm_tpv_campos_en_ticket}
                        data-forzaenvios={store.pos_forzaenvio}
                        data-direccion={`${store.direccion || ''} ${store.num_ext || ''} , Colonia ${store.neighborhood || ''}, ${store.postcode || ''} ${store.city || ''}, ${store.state || ''} - ${store.country || ''}`}
                        data-direccionid={store.treebesdireccionenvioid}
                        data-currencyid={store.pos_currency_id}
                      >
                        <ListItemText primary={store.almacen} />
                      </ListItem>
                    ))
                  }
                  </List>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          }
          {
            repartidor.length > 0 && (
              <ExpansionPanel className="w-100" expanded={repartidorPanel} onClick={() => setRepartidorPanel(!repartidorPanel)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Almacén Repartidor</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <List
                    className={classes.fullWidth}
                  >
                    {
                    repartidor.map(store => (
                      <ListItem
                        key={`StoreForm-${store.crmid}`}
                        button
                        component="button"
                        onClick={(event) => {
                          event.preventDefault();
                          const { currentTarget } = event;
                          selectStore(currentTarget, { ...store, pos_config_alm: conf });
                        }}
                        data-pos={store}
                        data-crmid={store.crmid}
                        data-name={store.almacen}
                        data-telefono={store.telefono}
                        data-email={store.email}
                        data-url_web={store.url_web}
                        data-image={(store.images && store.images.length > 0) ? store.images[0] : ''}
                        data-campos={store.talm_tpv_campos_en_ticket}
                        data-forzaenvios={store.pos_forzaenvio}
                        data-direccion={`${store.direccion || ''} ${store.num_ext || ''} , Colonia ${store.neighborhood || ''}, ${store.postcode || ''} ${store.city || ''}, ${store.state || ''} - ${store.country || ''}`}
                        data-direccionid={store.treebesdireccionenvioid}
                        data-currencyid={store.pos_currency_id}
                      >
                        <ListItemText primary={store.almacen} />
                      </ListItem>
                    ))
                  }
                  </List>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          }
          {
            dropshipping.length > 0 && (
              <ExpansionPanel className="w-100" expanded={dropshippingPanel} onClick={() => setDropshippingPanel(!dropshippingPanel)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Almacén Dropshipping</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <List
                    className={classes.fullWidth}
                  >
                    {
                      dropshipping.map(store => (
                        <ListItem
                          key={`StoreForm-${store.crmid}`}
                          button
                          component="button"
                          onClick={(event) => {
                            event.preventDefault();
                            const { currentTarget } = event;
                            selectStore(currentTarget, { ...store, pos_config_alm: conf });
                          }}
                          data-pos={store}
                          data-crmid={store.crmid}
                          data-name={store.almacen}
                          data-telefono={store.telefono}
                          data-email={store.email}
                          data-url_web={store.url_web}
                          data-image={(store.images && store.images.length > 0) ? store.images[0] : ''}
                          data-campos={store.talm_tpv_campos_en_ticket}
                          data-forzaenvios={store.pos_forzaenvio}
                          data-direccion={`${store.direccion || ''} ${store.num_ext || ''} , Colonia ${store.neighborhood || ''}, ${store.postcode || ''} ${store.city || ''}, ${store.state || ''} - ${store.country || ''}`}
                          data-direccionid={store.treebesdireccionenvioid}
                          data-currencyid={store.pos_currency_id}
                        >
                          <ListItemText primary={store.almacen} />
                        </ListItem>
                      ))
                    }
                  </List>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          }
        </Collapse>
      </Paper>
    </section>
  );
};

StoreForm.propTypes = {
  selectBC: PropTypes.func,
  selectStore: PropTypes.func,
  flagMethods: PropTypes.array,
  titleMethods: PropTypes.string,
  classes: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
};

StoreForm.defaultProps = {
  flagMethods: [],
  selectBC: () => {},
  selectStore: () => {},
  titleMethods: 'Seleccione caja/banco para Metodos de Pago',
};

// @ts-ignore
export default withStyles(styles)(StoreForm);
